import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import SEO from '../components/seo';
import { HeroState } from '../components/hero/hero-context';
import ContentColumn from '../components/content-column/content-column';
import PageWrapper from '../components/page-wrapper/page-wrapper';

const AgbPage = () => {
  const { t } = useTranslation('agb');

  return (
    <PageWrapper hero={false}>
      <SEO title={t('title')} />
      <HeroState clear />
      <Container>
        <Row>
          <ContentColumn md={{ offset: 1, span: 10 }}>
            <Trans i18nKey="content" ns="agb">
              <h1>Allgemeine Verkaufsbedingungen der Caffè A Mano GmbH</h1>
              <h2>§ 1 Geltungsbereich und Vertragsgrundlagen</h2>
              <h3>(1)</h3>
              <p>
                Diese Allgemeinen Verkaufsbedingungen (nachfolgend „
                <strong>Verkaufsbedingungen</strong>“) gelten für alle zwischen
                uns, der Caffè A Mano GmbH, Kleinhohenheimerstr. 5, 70619
                Stuttgart; als Verkäufer und Ihnen als unserem Kunden
                (nachfolgend auch „<strong>Kunde</strong>“) abgeschlossenen
                Verträge über die Lieferung von Waren und/oder die Erbringung
                von Leistungen.
              </p>
              <h3>(2)</h3>
              <p>
                Alle zwischen Ihnen und uns im Zusammenhang mit dem Kaufvertrag
                getroffenen Vereinbarungen ergeben sich insbesondere aus diesen
                Verkaufsbedingungen, unserer schriftlichen Auftragsbestätigung
                und unserer Annahmeerklärung. Der Einbeziehung von eigenen
                Vertragsbedingungen des Kunden wird hiermit widersprochen, es
                sei denn, es ist etwas anderes vereinbart.
              </p>
              <h3>(3)</h3>
              <p>
                In unseren Prospekten, Anzeigen und sonstigen Angebotsunterlagen
                enthaltene Abbildungen oder Zeichnungen sind nur annähernd
                maßgebend, soweit die darin enthaltenen Angaben nicht von uns
                ausdrücklich als verbindlich bezeichnet worden sind.
              </p>
              <h3>(4)</h3>
              <p>
                Verbraucher im Sinne dieser Verkaufsbedingungen ist jede
                natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt,
                die überwiegend weder ihrer gewerblichen noch ihrer
                selbständigen beruflichen Tätigkeit zugerechnet werden können.
                Unternehmer im Sinne dieser AGB ist jede natürliche oder
                juristische Person oder eine rechtsfähige Personengesellschaft,
                die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer
                selbstständigen beruflichen oder gewerblichen Tätigkeit handelt.
              </p>
              <h2>§ 2 Vertragsschluss</h2>
              <h3>(1)</h3>
              <p>
                Die auf unserer Webseite enthaltenen Produktbeschreibungen
                stellen keine verbindlichen Angebote durch uns dar, sondern
                dienen der Bekundung des unverbindlichen Interesses durch den
                Kunden.
              </p>
              <h3>(2)</h3>
              <p>
                Der Kunde kann über das auf der Webseite verfügbare
                Kontaktformular bzw. über weitere Kommunikationswege wie E-Mail,
                Brief oder Telefon die Verfügbarkeit der auf unserer Webseite
                präsentierten Produkte unverbindlich anfragen.
              </p>
              <h3>(3)</h3>
              <p>
                Wenn das vom Kunden angefragte Produkt verfügbar ist, werden wir
                dem Kunden per E-Mail ein Angebot nebst vorliegender AGB und
                Widerrufsbelehrung unterbreiten. Der Kunde kann dieses Angebot
                von fünf Tagen nach der Absendung des Angebots durch Bestätigung
                per E-Mail annehmen. Diese Bestätigung stellt die verbindliche
                Angebotsannahme dar und führt zum Vertragsschluss. Wir werden
                dem Kunden nach Erhalt dieser E-Mail eine Rechnung zur Zahlung
                per Vorkasse übersenden.
              </p>
              <h3>(4)</h3>
              <p>
                Nach Auftragsbestätigung durch den Kunden per E-Mail wird der
                Vertragstext von uns gespeichert.
              </p>
              <h2>§ 3 Widerrufsrecht & Widerrufsbelehrung</h2>
              <h3>(1)</h3>
              <p>
                Verbrauchern im Sinne von § 1 (4) steht nach folgender Maßgabe
                ein Widerrufsrecht zu.
              </p>
              <h3>Widerrufsbelehrung</h3>
              <h3>Widerrufsrecht</h3>
              <p>
                Sie haben das Recht, binnen vierzehn (14) Tagen ohne Angabe von
                Gründen diesen Vertrag zu widerrufen.
              </p>
              <p>
                Die Widerrufsfrist beträgt vierzehn (14) Tage ab dem Tag, an dem
                Sie oder ein von Ihnen benannter Dritter, der nicht der
                Beförderer ist, die letzte Ware in Besitz genommen haben bzw.
                hat.
              </p>
              <p>
                Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Caffè A Mano
                GmbH, Kleinhohenheimer Str. 5, 70619 Stuttgart, E-Mail:
                info@manument.com) mittels einer eindeutigen Erklärung (z. B.
                ein mit der Post versandter Brief oder E-Mail) über Ihren
                Entschluss, diesen Vertrag zu widerrufen, informieren. Sie
                können dafür das beigefügte Muster-Widerrufsformular verwenden,
                das jedoch nicht vorgeschrieben ist.
              </p>
              <p>
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
                Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
                Widerrufsfrist absenden.
              </p>
              <h3>Folgen des Widerrufs</h3>
              <p>
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle
                Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
                Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich
                daraus ergeben, dass Sie eine andere Art der Lieferung als die
                von uns angebotene, günstigste Standardlieferung gewählt haben),
                unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag
                zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses
                Vertrags bei uns eingegangen ist. Für diese Rückzahlung
                verwenden wir dasselbe Zahlungsmittel, das Sie bei der
                ursprünglichen Transaktion eingesetzt haben, es sei denn, mit
                Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem
                Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
              </p>
              <p>
                Wir können die Rückzahlung verweigern, bis wir die Waren wieder
                zurückerhalten haben oder bis Sie den Nachweis erbracht haben,
                dass Sie die Waren zurückgesandt haben, je nachdem, welches der
                frühere Zeitpunkt ist.
              </p>
              <p>
                Sie haben die Waren unverzüglich und in jedem Fall spätestens
                binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den
                Widerruf dieses Vertrags unterrichten, an uns zurückzusenden
                oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor
                Ablauf der Frist von vierzehn Tagen absenden.
              </p>
              <p>
                Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
              </p>
              <p>
                Sie müssen für einen etwaigen Wertverlust der Waren nur
                aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der
                Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht
                notwendigen Umgang mit ihnen zurückzuführen ist.
              </p>
              <h3>Ausschluss bzw. vorzeitiges Erlöschen des Widerrufsrechts</h3>
              <p>
                Das Widerrufsrecht besteht nicht bei Verträgen zur Lieferung von
                Waren, die nicht vorgefertigt sind und für deren Herstellung
                eine individuelle Auswahl oder Bestimmung durch den Verbraucher
                maßgeblich ist oder die eindeutig auf die persönlichen
                Bedürfnisse des Verbrauchers zugeschnitten sind.
              </p>
              <h3>(2)</h3>
              <p>
                Über das Muster-Widerrufsformular informieren wir nach der
                gesetzlichen Regelung wie folgt:
              </p>
              <h3>Muster-Widerrufsformular</h3>
              <p>
                Wenn Sie den Vertrag widerrufen wollen, können Sie dieses
                Formular verwenden, dessen Verwendung ist jedoch nicht
                vorgeschrieben.
              </p>
              <p>
                An:
                <br />
                Caffè A Mano GmbH
                <br />
                Kleinhohenheimer Str. 5<br />
                70619 Stuttgart
                <br />
                E-Mail: info@manument.com
              </p>
              <p>
                Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
                abgeschlossenen Vertrag über den Kauf der folgenden Waren
                (*)/die Erbringung der folgenden Dienstleistung (*)
              </p>
              <p>
                Bestellt am (*):
                <br />
                erhalten am (*):
                <br />
                Name des/der Verbraucher(s):
                <br />
                Anschrift des/der Verbraucher(s):
              </p>
              <p>
                Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf
                Papier)
                <br />
                Datum:
                <br />
                (*) Unzutreffendes streichen
              </p>
              <p>
                <strong>Ende der Widerrufsbelehrung</strong>
              </p>
              <h2>§ 4 Preise; Zahlung</h2>
              <h3>(1)</h3>
              <p>
                In unseren Preisen sind die Verpackungskosten und die
                gesetzliche Umsatzsteuer enthalten; Liefer- und Versandkosten
                sind in unseren Preisen jedoch nur enthalten, wenn hierüber eine
                gesonderte Vereinbarung mit Ihnen getroffen worden ist.
              </p>
              <h3>(2)</h3>
              <p>
                Der Kunde kann die Zahlung per Vorkasse nach Erhalt der Rechnung
                vornehmen.
              </p>
              <h2>§ 5 Lieferung</h2>
              <h3>(1)</h3>
              <p>
                Von uns angegebene Lieferzeiten berechnen sich vom Zeitpunkt des
                verbindlichen Vertragsschlusses sowie Vorkasse-Zahlung des
                Kaufpreises.
              </p>
              <h3>(2)</h3>
              <p>
                Es bestehen die folgenden Lieferbeschränkungen: Wir liefern nur
                an Kunden, die ihren gewöhnlichen Aufenthalt (Rechnungsadresse)
                in einem der nachfolgenden Länder haben und im selben Land eine
                Lieferadresse angeben können:
              </p>
              <p>Deutschland, Österreich, Schweiz</p>
              <h2>§ 6 Rechte bei Verzug und Mängeln; Haftung</h2>
              <h3>(1)</h3>
              <p>
                Soweit der gelieferte Gegenstand nicht die zwischen Ihnen und
                uns vereinbarte Beschaffenheit hat oder er sich nicht für die
                nach unserem Vertrag vorausgesetzte oder die Verwendung
                allgemein eignet oder er nicht die Eigenschaften, die Sie nach
                unseren öffentlichen Äußerungen erwarten konnten, hat, so sind
                wir zur Nacherfüllung verpflichtet. Dies gilt nicht, wenn wir
                aufgrund der gesetzlichen Regelung zur Verweigerung der
                Nacherfüllung berechtigt sind.
              </p>
              <h3>(2)</h3>
              <p>
                Die Nacherfüllung erfolgt nach Ihrer Wahl durch Beseitigung des
                Mangels (Nachbesserung) oder Lieferung neuer Ware, soweit der
                Kunde Verbraucher ist; andernfalls steht uns das Wahlrecht zu.
                Dabei müssen Sie uns eine angemessene Frist zur Nacherfüllung
                gewähren. Sie sind während der Nacherfüllung nicht berechtigt,
                den Kaufpreis herabzusetzen oder vom Vertrag zurückzutreten.
                Haben wir die Nachbesserung zweimal vergeblich versucht, so gilt
                diese als fehlgeschlagen. Wenn die Nacherfüllung fehlgeschlagen
                ist, sind Sie nach Ihrer Wahl berechtigt, den Kaufpreis
                herabzusetzen oder vom Vertrag zurückzutreten.
              </p>
              <h3>(3)</h3>
              <p>
                Sie können Schadensersatzansprüche wegen eines Mangels erst dann
                geltend machen, wenn die Nacherfüllung fehlgeschlagen ist.
                Unberührt bleibt Ihr Recht, weitergehende
                Schadensersatzansprüche nach Maßgabe der folgenden Absätze
                geltend zu machen.
              </p>
              <h3>(4)</h3>
              <p>
                Wir haften nach den gesetzlichen Bestimmungen für Schäden an
                Leben, Körper und Gesundheit, die auf einer schuldhaften
                Pflichtverletzung von uns, unseren gesetzlichen Vertretern oder
                unseren Erfüllungsgehilfen beruhen. Ferner haften wir nach den
                gesetzlichen Bestimmungen für sonstige Schäden, die auf
                vorsätzlichen oder grob fahrlässigen Vertragsverletzungen sowie
                Arglist von uns, unseren gesetzlichen Vertretern oder unseren
                Erfüllungsgehilfen beruhen. Soweit der Anwendungsbereich des
                Produkthaftungsgesetzes eröffnet ist, haften wir uneingeschränkt
                nach dessen Vorschriften.
              </p>
              <p>
                Wir haften auch im Rahmen einer Beschaffenheits- und/oder
                Haltbarkeitsgarantie, sofern wir eine solche bezüglich des
                gelieferten Gegenstands abgegeben haben. Treten Schäden ein, die
                zwar darauf beruhen, dass die von uns garantierte Beschaffenheit
                oder Haltbarkeit fehlt und treten diese Schäden jedoch nicht
                unmittelbar an der von uns gelieferten Ware ein, so haften wir
                hierfür nur dann, wenn das Risiko eines solchen Schadens
                ersichtlich von unserer Beschaffenheits- und
                Haltbarkeitsgarantie umfasst ist.
              </p>
              <h3>(5)</h3>
              <p>
                Beruht ein Schaden aufgrund von Verzug oder wegen eines Mangels
                auf der einfach fahrlässigen Verletzung einer wesentlichen
                Vertragspflicht, also der einfach fahrlässigen Verletzung einer
                Pflicht, deren Erfüllung die ordnungsgemäße Durchführung des
                Vertrags überhaupt erst ermöglicht und auf deren Einhaltung Sie
                als Käufer regelmäßig vertrauen dürfen, so ist unsere Haftung
                auf den bei Vertragsschluss vorhersehbaren und vertragstypischen
                Schaden begrenzt. Das Gleiche gilt, wenn Ihnen Ansprüche auf
                Schadensersatz statt der Leistung zustehen.
              </p>
              <h3>(6)</h3>
              <p>
                Weitergehende Haftungsansprüche gegen uns bestehen nicht und
                zwar unabhängig von der Rechtsnatur der von Ihnen gegen uns
                erhoben Ansprüche. Hiervon unberührt bleibt unsere Haftung nach
                vorstehendem Absatz 3.
              </p>
              <h2>§ 6 Eigentumsvorbehalt</h2>
              <p>
                Die gelieferte Ware (Vorbehaltsware) bleibt bis zur
                vollständigen Zahlung aller Forderungen aus diesem Vertrag unser
                Eigentum.
              </p>
              <h2>§ 7 Eigentumsvorbehalt</h2>
              <p>
                Die gelieferte Ware (Vorbehaltsware) bleibt bis zur
                vollständigen Zahlung aller Forderungen aus diesem Vertrag unser
                Eigentum.
              </p>
              <h2>§ 8 Schlussbestimmung, anzuwendendes Recht</h2>
              <p>
                Auf unseren Vertrag findet das Recht der Bundesrepublik
                Deutschland Anwendung. Die Anwendung des UN-Kaufrechts ist
                ausgeschlossen. Die gesetzlichen Vorschriften zur Beschränkung
                der Rechtswahl und zur Anwendbarkeit zwingender Vorschriften
                insbes. des Staates, in dem der Kunde als Verbraucher seinen
                gewöhnlichen Aufenthalt hat, bleiben unberührt.
              </p>
            </Trans>
          </ContentColumn>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default AgbPage;
